.btn {
    padding: 16px;
    border-radius: 8px;
    font-family: $font-fam;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    outline: none;
    width: 100%;
    cursor: pointer;
}
.btn-primary {
    background-color: $blue;
    color: $white;
    border: 1px solid $blue;
}
