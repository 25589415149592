.socialmedia_container {
    .social_button {
        display: flex;
        flex-direction: column;
        gap: 12px;

        a {
            text-decoration: none;
            button {
                width: 100%;
                padding: 16px;
                border-radius: 8px;
                font-weight: 400;
                font-size: 13px;
                line-height: 140%;
                text-align: center;
                border: none;
                color: #212121;
            }
        }
    }
}
.social_button .all_media button {
    background-color: #f2f2f2;
    color: #212121;
    cursor: pointer;
}
.social_button .all_media:hover button {
    background-color: #f8f8f8;
    color: #212121;
}
.social_button .fb_media button {
    background: #1877f2;
    color: #ffffff;
}
.social_button .fb_media:hover button {
    background: #1876f2be;
    color: #212121;
}
.social_button .tw_media button {
    background-color: #55acee;
    color: #ffffff;
}
.social_button .tw_media:hover button {
    background-color: #55aceeb9;
    color: #212121;
}
.social_button .ig_media button {
    background: linear-gradient(
        268.65deg,
        #9a36bc 2.49%,
        #f26438 52.97%,
        #ffc15a 97.52%
    );
    color: #ffffff;
}
.social_button .ig_media:hover button {
    background: linear-gradient(
        268.65deg,
        #9a36bcbe 2.49%,
        #f26338c7 52.97%,
        #ffc05ab9 97.52%
    );
    color: #212121;
}
