@mixin dflexrow {
    display: flex;
    justify-content: center;
    align-items: center;
}
@mixin dflexsb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@mixin dflexcol {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/// Responsive
@mixin breakpoint($point) {
    @if $point == menu {
        @media (min-width: 1100px) {
            @content;
        }
    } @else if $point == xxxl {
        @media (min-width: 1640px) {
            @content;
        }
    } @else if $point == xxl {
        @media (min-width: 1480px) {
            @content;
        }
    } @else if $point == xl {
        @media (min-width: 1200px) {
            @content;
        }
    } @else if $point == lg {
        @media (min-width: 992px) {
            @content;
        }
    } @else if $point == md {
        @media (min-width: 768px) {
            @content;
        }
    } @else if $point == sm {
        @media (min-width: 576px) {
            @content;
        }
    } @else if $point == xxs {
        @media (min-width: 375px) {
            @content;
        }
    } @else if $point == xs {
        @media (min-width: 414px) {
            @content;
        }
    }
}
