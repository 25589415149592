$primary-color: #1d1d1d;
$secondary-color: #181818;
$blue: #004ef9;
$blueII: #042137;
$white: #ffffff;
$border-color: #dadada;
$gray-3: #787878;
$dark: #211e27;
// $font-fam: "CircularStdRegular";
$font-fam: "Manrope", HelveticaNeue, sans-serif;
$font-famII: "TT Norms Pro";
$bg-input: #ebebeb;
