.card-1 {
    @include dflexrow;
    height: 177px;
    border-radius: 15px;
    &__about {
        font-weight: 700;
        font-size: 21.7995px;
        line-height: 26px;
        width: 50%;
        height: 100%;
        padding: 12px;
        border-radius: 15px 0px 0px 15px;
        @include dflexcol;
        &__maincontent {
            font-weight: 700;
            font-size: 21.7995px;
            line-height: 26px;
        }
        &__subtext {
            font-weight: 400;
            font-size: 8.12404px;
            line-height: 10px;
        }
    }
    &__image {
        width: 100%;
        height: 100%;
        &__normal {
            background-color: #ffbe88;
            color: $white;
        }
        &__reverse {
            background-color: #b8dcfb;
        }
        &__noimage {
            border: 1px solid black;
        }
        img {
            border-radius: 15px;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
