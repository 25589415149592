.navbar {
    background-color: $secondary-color;
    padding: 16px;
    position: fixed;
    top: 0px;
    z-index: 3;
    width: 100%;
    &__menu {
        color: $white;
        @include dflexsb;
        .ant-space-item {
            display: flex;
        }
        &__title {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: $white;
        }
        &__venuename {
            width: calc(100% - 30px);
            font-size: 18px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            color: #ffffff;
            text-transform: capitalize;
            font-weight: 500;
            font-size: 18.3354px;
            line-height: 22px;

            a {
                color: #ffffff;
                text-decoration: none;
            }
        }

        //   .nav-venuename a {
        //   }
    }
    &__closeicon {
        background-color: aqua;
    }
    &__sidebar {
        max-width: 320px;
        background-color: $white !important;
        .ant-drawer-header {
            border-bottom: none;
            .ant-drawer-header-title {
                flex-direction: row-reverse;
                .ant-drawer-close {
                    margin: 0;
                    .anticon-close {
                        color: $primary-color;
                        font-size: 16px;
                    }
                }
            }
        }

        .ant-drawer-body {
            @include dflexcol;
            justify-content: space-between;
        }
        &__topcontent {
            &__logo {
                margin-bottom: 64px;
                width: 100px;
                img {
                    width: 100%;
                }
            }
            ul {
                @include dflexcol;
                gap: 30px;
                li {
                    list-style: none;
                    cursor: pointer;
                    div,
                    a {
                        font-family: $font-fam;
                        color: $primary-color;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                    }
                }
            }
        }
        &__content {
            @include dflexcol;
            font-family: $font-fam;
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            gap: 18px;
            color: $gray-3;
            .content-II {
                cursor: pointer;
            }
            &__lang {
                display: flex;
                span {
                    &::after {
                        content: ".";
                        font-size: 24px;
                        margin: 0 4px;
                    }
                }
            }
        }
    }
}
.subscribe__modal {
    width: 100%;

    .form_container {
        input {
            border-radius: 0px !important;
            font-weight: 500;
            font-size: 14.4846px;
            line-height: 139.3%;
            color: #6c6c6c;
            padding: 16px 20px;
            &::placeholder {
                font-weight: 500;
                font-size: 14.4846px;
                line-height: 139.3%;
                color: #6c6c6c;
            }
        }
        .btn {
            border-radius: 0px;
            font-size: 18px;
            font-weight: 500;
            line-height: 140%;
            padding: 12px !important;
        }
    }
    .ant-select-selector {
        border-radius: 0px !important;
    }
    .ant-modal-content {
        border-radius: 0px;
        padding: 16px;
    }
    .ant-modal-title {
        font-size: 28px;
        font-weight: 700;
        line-height: 38px;

        font-family: $font-fam;
        padding: 16px 0 6px 0;
        @include dflexrow;
    }
    .ant-modal-close-icon {
        color: $gray-3;
    }
    .modal_paragraph {
        font-family: $font-fam;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: $gray-3;
        padding: 0px;
    }
    .ant-modal-footer {
        display: none;
    }
    .form_field {
        .formfield__smselect {
            display: flex;
            flex-wrap: wrap;
            .phone-label {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 12px;
                background-color: #ebebeb;
            }
            input {
                height: 51px;
            }
            .ant-select-selector {
                height: 51px;
                outline: none;
                background-color: #ebebeb;
                border: none;
                outline: none;
                box-shadow: none;
                img {
                    background-color: #ebebeb;
                }
            }
            .ant-select {
                outline: none;
                border: none;
            }
            .questionnaire-number {
                width: 100%;
            }
            .ant-input-group {
                display: flex;
            }
            input {
                border-radius: 0px 8px 8px 0px;
            }
        }
        div {
            input {
                background-color: $bg-input;
                border: none;
            }
        }
    }
    .ant-select-selection-item {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.phone-label {
    img {
        width: 32px;
        max-width: 32px;
        margin-right: 8px;
        object-fit: contain;
    }
}
.phone-option {
    font-family: "Sofia-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    border: none !important;
    img {
        max-height: 15px;
        width: 22px;
        max-width: 22px;
        margin-right: 8px;
        object-fit: contain;
    }
}
@media only screen and (min-width: 375px) {
    .subscribe__modal {
        .ant-modal-content {
            padding: 26px !important;
        }
    }
}
