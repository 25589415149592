@font-face {
    font-family: "TTNormsPro";
    src: url(../../Fonts/TTNormsPro.otf);
}
@font-face {
    font-family: "TT Norms Pro";
    src: url(../../Fonts/TTNormsRegular.otf);
}
@font-face {
    font-family: "TT Norms Bold";
    src: url(../../Fonts/TTNormsProBold.otf);
}
@font-face {
    font-family: "TT Norms Italic";
    src: url(../../Fonts/TTNormsProLight.otf);
}
@font-face {
    font-family: "TT Norms Pro Thin";
    src: url(../../Fonts/TTNormsProThin.otf);
}

@font-face {
    font-family: "TT Norms Medium";
    src: url(../../Fonts/TTNormsProMedium.otf);
}
@font-face {
    font-family: "HelveticaNeue";
    src: url(../../Fonts/HelveticaNeueMedium.ttf);
}

@font-face {
    font-family: "CircularStdBold";
    src: url(../../Fonts/CircularStdBold.ttf);
}
@font-face {
    font-family: "CircularStdRegular";
    src: url(../../Fonts/CircularStdRegular.ttf);
}
