.whole_wrapper {
    .listing-menu {
        min-height: calc(100vh - 204px);
        .top-bar {
            display: flex;
            border-bottom: 1px solid #f1f1f1;
            background-color: $white;
            padding: 9px 14px;
            justify-content: space-between;
            position: fixed;
            width: 100%;
            top: 56px;
            z-index: 3;

            .topbar_left {
                display: flex;
            }
            .topbar_right {
                background-color: $primary-color;
                color: $white;
                padding: 4px 12px;
                border-radius: 185px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: capitalize;
                cursor: pointer;
                .viewmenu_arrow {
                    color: $white;
                    font-size: 12px;
                    display: flex;
                    align-items: flex-end;
                    margin-left: 3px;
                    margin-top: 2px;
                }
            }
            .filter-icon,
            .search-icon {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #f1f1f1;
                margin-right: 8px;
                cursor: pointer;
                img {
                    width: 16px;
                    height: 16px;
                    object-fit: cover;
                }
                .bisearch {
                    color: #211e27;
                    font-size: 16px;
                }
            }
            .filter-icon,
            .search-icon:hover {
                background-color: #948f8f34;
                transition: all 0.6s ease-in;
            }
        }
        .search-bar {
            position: fixed;
            display: flex;
            justify-content: center;
            top: 56px;
            left: -100%;
            z-index: 10000;
            background-color: $white;
            height: 50px;
            padding: 8px 14px 8px 14px;
            width: 100%;
            transition: all 0.5s ease-out;
            .input-search {
                width: calc(100% - 98px);
                position: relative;
                input {
                    border: 1px solid #f1f1f1;
                    border-radius: 185px;
                    padding: 5px 18px;
                    height: 35px;
                    padding-left: 30px;
                    width: 100%;
                    outline: none;
                }
                .bisearch_inner-icon {
                    position: absolute;
                    left: 10px;
                    top: 10px;
                    color: #211e27;
                }
            }
            .cancel-btn {
                width: 86px;
                border: 1px solid #f1f1f1;
                border-radius: 185px;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #211e27;
                padding: 8px 18px;
            }
        }
        .search-bar.active {
            display: flex;
            align-items: center;
            justify-content: space-between;
            left: 0;
            top: 56px;
            height: 50px;
            z-index: 1000;
            width: 100%;
        }
        .menu_wrapper {
            padding: 120px 16px 16px 16px;

            background-color: #fdfdfd;
            h2 {
                text-transform: capitalize;
                font-weight: 700;
                font-size: 23px;
                line-height: 28px;
                color: $primary-color;
                margin-bottom: 8px;
            }
            .menu_innerbox {
                display: flex;
                flex-direction: column;
                gap: 16px;
                a {
                    width: 100%;
                    @media only screen and (min-width: 768px) {
                        width: calc(50% - 8px);
                    }
                    @media only screen and (min-width: 1280px) {
                        width: calc(33.33% - 12px);
                    }

                    .menu_items {
                        width: 100%;
                        box-shadow: none !important;
                        border-radius: 4px;
                        border: 1px solid #f1f1f1;
                        height: 36.5vw;
                        background-color: none !important;

                        box-shadow: (0 0 #0000, 0 0 #0000),
                            (0 0 #0000, 0 0 #0000),
                            0px 0px 0px rgba(29, 29, 29, 0.25),
                            0px 0px 15px rgba(29, 29, 29, 0.04) !important;

                        @media only screen and (min-width: 768px) {
                            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15) !important;
                            border-radius: 0.25rem;
                            height: 160px;
                        }
                        @media only screen and (min-width: 1280px) {
                            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15) !important;
                            border-radius: 0.25rem;
                            height: 190px;
                        }
                    }

                    .menuitems.sold {
                        background-color: rgba(0, 0, 0, 0.5);
                    }
                }
                .sold_out,
                .soldinactive {
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    border-top-left-radius: 0.25rem;
                    border-bottom-left-radius: 0.25rem;
                    position: absolute;
                }

                .sold_out {
                    max-width: 45%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    font-size: 16px;
                    line-height: 22px;
                    width: 36vw;
                    @media only screen and (min-width: 390px) {
                        width: 36.1vw;
                    }
                }
                .soldinactive {
                    border-radius: 8px;
                    width: 100% !important;
                    max-width: 100% !important;
                    opacity: 1;
                    @media only screen and (min-width: 768px) {
                        border-radius: 0.25rem;
                    }
                }
                .availiable {
                    background-color: rgba(0, 0, 0, 0.2);
                }
                .sold_span,
                .sold_spancenter {
                    color: $white;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 16px;
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    top: 0px;
                    width: 100%;
                    height: 100%;
                }
                .sold_span {
                    position: absolute;
                }
                h2 {
                    width: 100%;
                    overflow-wrap: break-word;
                    font-weight: 700;
                    font-size: 23px;
                    line-height: 28px;
                    color: $primary-color;
                    margin-bottom: 24px;
                    text-transform: capitalize;
                }
                .menuitems_wrap {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 0.75rem;
                }
                .menu_itemsactive,
                .menu_items {
                    display: flex;
                    border-radius: 0.25rem;
                    border: 1px solid #dddddd6b;
                    height: 36.5vw;
                    position: relative;
                    background-color: #ffffff;
                    transition: all 0.1s ease-in-out;
                    box-shadow: (0 0 #0000, 0 0 #0000), (0 0 #0000, 0 0 #0000),
                        0px 0px 0px rgba(29, 29, 29, 0.25),
                        0px 0px 15px rgba(29, 29, 29, 0.04) !important;
                    &:hover {
                        transform: scale(0.99);
                        @include breakpoint(md) {
                            transform: scale(1) !important;
                        }
                    }

                    @include breakpoint(md) {
                        height: 160px;
                    }
                    @media only screen and (min-width: 1280px) {
                        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15) !important;
                        border-radius: 0.25rem;
                        height: 190px;
                    }
                    .listingproduct_img {
                        border-radius: 4px 0 0 4px;
                        aspect-ratio: 1/1;
                        position: relative;
                        @media only screen and (min-width: 768px) {
                            max-width: 45%;
                        }
                        img {
                            width: 100%;
                            height: 100%;
                            border-top-left-radius: 4px;
                            border-bottom-left-radius: 4px;
                            object-fit: cover;
                        }
                    }
                    .listingproduct_none {
                        display: none;
                    }
                }

                .menu_inner_content,
                .menuinnercontent_opacity,
                .menu-wholecontent {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin: 12px;
                    width: 50%;

                    .menu_subcontentI {
                        width: 100%;
                        div {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 4px;
                        }
                        .menu_status {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: #004ef9;
                            width: 74px;
                            padding: 4px;
                            text-transform: uppercase;
                            border-radius: 32px;
                            color: $white;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 15px;
                            margin: 0;
                        }
                        .data_normal {
                            display: none;
                        }
                        h4 {
                            margin: 0px;
                            text-transform: capitalize;
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 140%;
                            margin: 0 0 0.25rem 0;
                            text-transform: capitalize;
                            // width: 250px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            color: #1d1d1d;
                        }
                        p {
                            font-family: $font-fam;
                            color: #4d4d4d;
                            margin-bottom: 4px;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 140%;
                            margin-bottom: 4px;
                            word-break: break-all;
                        }
                        .aifire {
                            width: 18px;
                            height: 18px;
                            border-radius: 50%;
                            background-color: #004ef9;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin: 0;
                            .aifire-icon {
                                font-size: 12px;
                                color: $white;
                            }
                        }
                    }
                    .menu_subcontent {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .price {
                            font-family: $font-fam;
                            font-weight: 500;
                            color: #1d1d1d;
                            font-size: 14px;
                            line-height: 20px;
                            font-weight: 400;
                            line-height: 140%;
                        }
                        .menu__GVD {
                            display: flex;
                            column-gap: 4px;
                            span {
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 140%;
                                text-transform: uppercase;
                                color: #646268;
                            }
                        }
                    }
                }
                .menuinnercontent_opacity {
                    opacity: 0.4 !important;
                }
                .menu-wholecontent {
                    width: 100% !important;
                }

                .cat_name {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 28px;
                    color: $primary-color;
                    text-transform: capitalize;
                    margin-bottom: 12px;
                    letter-spacing: -0.24px;
                }
                .catname_none {
                    display: none !important;
                }
            }
            .menuuu {
                background-color: rgba(0, 0, 0, 0.5) !important;
            }
        }
    }
    .view_orderbtn {
        width: 100%;
        padding: 20px;
        background-color: $white;
        position: fixed;
        bottom: 0px;
        box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.1);
        button {
            display: flex;
            justify-content: center;
            .viewordr_count_active {
                background-color: $white;
                border-radius: 50%;
                width: 28px;
                height: 28px;
                padding: 12px;
                font-weight: 600;
                font-size: 15px;
                line-height: 18px;
                text-align: center;
                color: $primary-color;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 20px;
                box-shadow: 6px 6px 11px rgba(0, 0, 0, 0.1);
            }
            .viewordr_count {
                display: none;
            }
        }
    }
}
.ant-drawer-content-wrapper {
    box-shadow: none !important;
}
// .ant-drawer-mask {
//     background: none !important;
// }
.viewmenu_drawer {
    position: absolute;
    top: 107px;
    box-shadow: 0px 2px 11px rgb(0 0 0 / 10%);

    .ant-drawer-header {
        display: none;
    }
    .filter__drawer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 73vh;
        position: relative;
        @media only screen and (min-width: 768px) {
            height: 79vh;
        }
        @media only screen and (min-width: 1024px) {
            height: 86vh;
        }
        @media only screen and (min-width: 1440px) {
            height: 88.6vh;
        }
    }
    .menu_categoryitem {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: $primary-color;
        text-transform: capitalize;
        border-bottom: 1px solid #f1f1f1;
        padding: 16px 0px;
        font-family: "TT Norms Pro";
    }
}
.listing_drawer {
    position: absolute;
    top: 101px;
    box-shadow: 0px 2px 11px rgb(0 0 0 / 10%);
    .ant-drawer-header {
        padding: 16px;
    }
    .ant-drawer-close {
        display: none;
    }
    .ant-drawer-mask {
        background-color: transparent !important;
    }
    .ant-drawer-body {
        padding: 0px 12px;
        overflow: scroll;
    }
    .filter__drawer {
        .filter_content_wrapper {
            padding-bottom: 110px;
            label {
                &:last-child {
                    border-bottom: none;
                }
            }
        }
        min-height: 91vh;
        height: auto;
        margin-bottom: 80px;
        overflow: scroll;
    }
    .drawer_dietry_content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        border-bottom: 1px solid #f1f1f1;
        &:hover {
            background-color: #e9e9e9;
        }
        input {
            width: 20px !important;
            height: 20px !important;
            border-radius: 8px !important;
        }
        span {
            font-family: $font-fam;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: $primary-color;
        }
        .ant-drawer-title {
            font-family: $font-fam;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            color: $primary-color;
        }
    }
}
.listing_drawer .ant-drawer-body::-webkit-scrollbar {
    display: none;
}

.listing_drawer .ant-drawer-body {
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;
    height: auto;
}
.filter_applybtn {
    position: fixed;
    bottom: 0px;
    padding: 16px;
    width: 320px;
    background-color: $white;
    box-shadow: 0px 2px 11px rgb(0 0 0 / 10%);
    button {
        background-color: $primary-color;
        padding: 16px;
        width: 100%;
        color: $white;
        border: none;
        border-radius: 8px;
        text-align: center;
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
    }
}
.sold_status {
    position: absolute;
    width: 100%;
    top: calc(50% - 10px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
    color: #ffffff;
    z-index: 2;
}
.status_active {
    width: calc(100% - 55% - 14px) !important;
}
.image_overlay::after {
    content: "";
    position: absolute;
    z-index: 1;
    border-radius: 6px 0px 0px 6px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    // background: linear-gradient(
    //     180deg,
    //     rgba(43, 43, 43, 0.3) 0%,
    //     rgba(0, 0, 0, 0.6) 88.59%
    // );
    background-color: rgba(0, 0, 0, 0.5);
}
