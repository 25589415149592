.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background-color: #ffffff;
    padding-top: 24px;

    .powered_by {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        text-align: center;
        p {
            text-align: center;
            color: #211e27;
            margin: 0px 8px 0px 0px;
            padding: 0px;
            font-size: 12px;
            line-height: 18px;
        }
    }
    .powered_by:before,
    .powered_by:after {
        content: "";
        display: inline-block;
        width: 90px;
        height: 0.5px;
        vertical-align: middle;
        background-color: #dadada;
    }

    .powered_by:before {
        margin-right: 10px;
    }
    .powered_by:after {
        margin-left: 10px;
    }

    .welcomepage__desc {
        font-weight: 400;
        font-size: 14px;
        font-family: $font-fam;
        line-height: 18px;
        text-align: center;
        color: #646268;
        margin-bottom: 12px;
    }
    img {
        width: 64px;
        object-fit: cover;
    }
    .learnmore {
        text-align: center;
        text-decoration-line: underline;
        color: #211e27;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
    }
}
