.offer {
    &__title {
        margin-bottom: 20px;
        .subcontent {
            span {
                text-transform: capitalize;
            }
        }
    }
    &__cardwrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    h1 {
        margin-bottom: 4px;
    }
}
@media only screen and (min-width: 768px) {
    .offer {
        &__cardwrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .card-1 {
                width: calc(50% - 12px);
            }
        }
    }
}
@media only screen and (min-width: 1024px) {
    .offer {
        &__cardwrapper {
            .card-1 {
                width: calc(33.33% - 16px);
            }
        }
    }
}
