.email_form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .drawer-title {
        margin: 0;
        font-family: $font-fam;
        text-align: center;
        font-size: 16px;
        line-height: 22px;
    }
    .form_inputfield {
        font-family: $font-fam;
        .country_smselect {
            width: 60px;
            .select_input {
                background: #ebebeb;
                border-radius: 11px 0px 0 11px;
                border: none;
                width: 100%;
                height: 100%;
                font-family: $font-fam;
            }
        }
        .related_inputfield {
            input {
                font-family: $font-fam;
                border-radius: 0px 11px 11px 0px;
            }
            width: calc(100% - 60px);
        }
        textarea,
        input {
            background: #ebebeb;
            border-radius: 11px;
            border: none;
            padding: 15px 12px;
            outline: none;
            font-family: $font-fam;
        }
        textarea {
            height: 122px;
            width: 100%;
            font-family: $font-fam;
        }
        .errmsg {
            margin-top: 4px;
            font-family: $font-fam;
        }
    }

    .country_inputfield {
        width: 100%;
        display: flex;
        gap: 4px;
    }
    .email-desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        color: #000000;
        margin: 0;
        font-family: $font-fam;
    }
    .email_submitbtn {
        background: #004ef9;
        border-radius: 11px;
        border: none;
        font-weight: 500;
        font-size: 19px;
        line-height: 40px;
        letter-spacing: -0.42px;
        color: #ffffff;
        text-align: center;
        font-family: $font-fam;
    }
    select:invalid {
        color: red;
    }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
