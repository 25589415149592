.feedback_page {
    .ant-rate {
        width: 100%;
        display: flex;
        gap: 14px;
        padding: 24px 0;
        border-top: 1px solid #e6e6e6;
        transform: matrix(1, 0, 0, -1, 0, 0);
        .anticon svg {
            width: 40px;
            height: 40px;
        }
    }
    .feedback_mid {
        padding: 24px 0;
        .feedbacks_wrapper {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            padding: 24px 0;
            .feedback_content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 8px;
                width: calc(33.33% - 14px);

                .fcontent_img {
                    position: relative;
                    cursor: pointer;
                    max-height: 98px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .fcontent_img:before {
                    content: "";
                    display: block;
                    position: absolute;
                    height: 0%;
                    width: 100%;
                    border-radius: 50%;
                    bottom: 0;
                    transition: height 0.5s ease-out;
                    background: linear-gradient(
                        to bottom,
                        transparent 0%,
                        rgba(212, 204, 204, 0.61) 100%
                    );
                }
                .fcontent_img:hover:before {
                    height: 100%;
                }
                div {
                    &:nth-child(2) {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 140%;
                        text-align: center;
                        color: #7a7a7a;
                        margin-top: 12px;
                        cursor: pointer;
                    }
                }
            }
            .active {
                background: linear-gradient(
                    to top,
                    transparent 0%,
                    rgba(212, 204, 204, 0.61) 100%
                );
            }

            .feedback_contentactive {
                background-color: #212121;
            }
        }
    }
    .ftextarea_wrapper {
        .feedback_textarea {
            width: 100%;
            margin: 18px 0px 0 0px;
            border: 0.3px solid rgba(122, 122, 122, 0.7);
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            color: #212121;
            padding: 8px;
            outline: none;
            font-family: $font-fam;
        }
    }
    button {
        border-radius: none !important;
        margin-top: 18px;
    }
}
@media only screen and (min-width: 768px) {
    .feedback_page {
        .feedback_mid {
            .feedbacks_wrapper {
                .feedback_content {
                    width: calc(20% - 16px);
                }
            }
        }
    }
}
@media only screen and (min-width: 1024px) {
    .whole_wrapper {
        .feedback_page {
            max-width: 500px;
            height: calc(100vh);
            border: 1px solid #2121212c;
            border-top: none;
            border-bottom: none;
            position: fixed;
            top: 0px;
            overflow: scroll;
            margin-left: calc(50vw - 250px);

            .feedback_mid {
                .feedbacks_wrapper {
                    .feedback_content {
                        width: calc(33.33% - 16px);
                    }
                }
            }
        }
    }
    .page_footer {
        display: none;
    }
}
.feedback_page {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.feedback_page::-webkit-scrollbar {
    display: none;
}
