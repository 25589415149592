.error-container {
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    .text-container {
        background-color: #ffffff;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 56px;
        h2 {
            font-weight: 600;
            font-size: 39px;
            line-height: 48px;
            color: #212121;
            margin-bottom: 0;
        }
        h1 {
            font-family: mosk700;
            font-weight: 600;
            font-size: 128px;
            line-height: 1;
            color: #212121;
            margin-bottom: 30px;
        }
        h3 {
            font-weight: 400;
            font-size: 31px;
            line-height: 40px;
            color: #212121;
            text-align: center;
        }
    }
    .error__image {
        img {
            object-fit: contain;
            width: 84%;
            height: 79vw;
        }
    }
}
