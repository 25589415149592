.nvm_wrapper .cover_container {
    top: 0px;
    height: auto;
    min-height: 100vh;
}
.profilesection {
    height: 100vh;
    overflow: scroll;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    // padding-bottom: 20px;
    // margin-top: 56px;

    @media only screen and (min-width: 860px) {
        width: 60%;
        margin: auto;
        max-width: 600px;
        // border-inline: 1px solid #dddddd;
    }

    .image_innercontent {
        .cover-img {
            width: 100%;
            max-height: 100px;
            object-fit: cover;
            background-color: #808080;
        }
        .profile_content {
            position: relative;
            padding: 55px 24px 24px 24px;
            .ld_logo {
                top: -55px;
                left: calc(50% - 55px);
                position: absolute;
                background-color: #212121;
                width: 110px;
                height: 110px;
                border-radius: 50%;
                border: 2px solid #ffffff;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }
            .ld_title {
                font-weight: 700;
                font-size: 20px;
                line-height: 22px;
                text-transform: uppercase;
                font-family: $font-famII;
                text-align: center;
                margin: 16px 0 0 0;
            }
            .bio-data {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                width: 80%;
                line-break: anywhere;
                -webkit-box-orient: vertical;
                text-align: center;
                font-size: 14px;
                line-height: 20px;
                margin: 8px auto;
                // padding: 8px 0;
            }
            .view-ophour {
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;
                text-decoration-line: underline;
                font-family: $font-famII;
                cursor: pointer;
                text-align: center;
                // color: $blueII;
                margin-top: 3px;
            }
            .ld_socialicons {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 12px;
                margin: 12px 0 16px 0;
                a {
                    color: $primary-color;
                }
                svg {
                    cursor: pointer;
                }
            }
            .updated-contentWrapper {
                display: flex;
                flex-direction: column;
                // align-items: center;
                gap: 14px;
                // margin-bottom: 14px;
                .ant-collapse {
                    border-radius: 0;
                    border: 1px solid #042137;
                    .ant-collapse-content {
                        border-radius: 0;
                        .ant-collapse-content-box {
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            gap: 12px;

                            a {
                                width: 60px;
                                margin: auto;
                                button {
                                    background-color: #000000;
                                    color: #ffffff;
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 19px;
                                    border: none;
                                    padding: 8px 16px;
                                    border-radius: 4px;
                                    display: flex;

                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    .ant-collapse-item {
                        border-radius: 0;
                    }
                    .ant-collapse-header {
                        background-color: #fff;
                        border-radius: 0;
                        text-align: center;
                        text-transform: capitalize;
                        .ant-collapse-expand-icon {
                            display: none;
                        }
                        .ant-collapse-header-text {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;
                            text-align: center;
                            color: #042137;
                        }
                    }
                }
            }
            .updated-content {
                // &:last-child {
                //     display: none;
                // }
                width: 100%;
                font-size: 16px;
                line-height: 22px;
                font-weight: 400;
                div {
                    &:nth-child(1) {
                        // font-weight: 700;
                        text-align: center;
                    }
                }
            }
            .ldcontent-btnlist {
                display: flex;
                flex-direction: column;
                gap: 14px;
                width: 100%;
                font-family: $font-famII;
                a {
                    text-decoration: none;
                }

                button {
                    @include dflexsb;
                    // gap: 8px;
                    padding: 16px;

                    font-weight: 400;
                    font-size: 16px;
                    line-height: 17px;
                    text-align: center;
                    color: #ffffff !important;
                    width: 100%;

                    // background-color: #ffffff;
                    // border: 1px solid #042137;

                    position: relative;
                    text-align: center;
                    border-radius: 8px;
                    cursor: pointer;
                    .updated-socialicon {
                        height: 17px;
                    }
                    div {
                        width: 100%;
                        text-align: center;
                        font-family: $font-famII;
                        padding: 0px 16px;
                    }
                }
                .tools-btn {
                    position: relative;
                    img,
                    svg {
                        position: absolute;
                        left: 16px;
                    }
                }
                .pdf-btn {
                    // @include dflexrow;
                    // flex-direction: row-reverse;
                    text-transform: capitalize;
                    width: 100%;
                    position: relative;
                    .grshare {
                        position: absolute;
                        right: 16px;
                    }
                }
                .md-image {
                    img {
                        max-height: 181px;
                        width: 100%;
                        height: 100%;
                    }
                }
                .delivery-text {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    font-family: $font-famII;
                    margin: 6px 0 12px 0;
                }
            }
        }

        .black-pcontent {
            background-color: #000000;
        }
    }

    .footer {
        padding-bottom: 20px;
        p {
            font-family: $font-famII;
        }
        .welcomepage__desc {
            margin: 0;
        }
    }
}
/* Hide scrollbar for Chrome, Safari and Opera */
.profilesection::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.profilesection {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.dark {
    background-color: #212121;
    color: #fff !important;
}
.light {
    background-color: #fff;
    color: #042137 !important;
}
.theemetext {
    width: 100%;
    text-align: end;
}
.ant-drawer-content-wrapper {
    height: auto;
}
.operating_hour {
    position: absolute;
    bottom: 0px;
    height: 307px !important;
    box-shadow: 1px 0px 6px rgba(50, 50, 50, 0.75);
    @media only screen and (min-width: 1024px) {
        max-width: 600px;
        left: calc(50% - 300px);
    }
    .ant-drawer-header {
        border: none;
        padding-bottom: 0;
        padding-top: 24px;
        .ant-drawer-close {
            // display: none;
            margin: 0;
            color: $primary-color;
        }
    }
    .ant-drawer-header-title {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
    }
    .ant-drawer-title {
        text-align: start;
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        color: $primary-color;
    }
    .weekday-col {
        padding: 4px;
        display: flex;
        justify-content: space-between;
        span {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            &:nth-child(1) {
                color: #737373;
            }
            &:nth-child(2) {
                color: #171717;
            }
        }
    }
}
.react-player {
    width: 100% !important;
    // .video-stream {
    //     left: 0;
    //     width: 100%;
    // }
}
.general-drawer {
    background-color: #fff;
    border-radius: 43px 43px 0 0;
    @media only screen and (min-width: 1024px) {
        max-width: 600px;
        left: calc(50% - 300px);
    }
    .ant-drawer-header {
        border-bottom: none;
        position: absolute;
        right: 0px;
        top: 0px;
        padding: 16px 24px;
        width: 100%;
        background: #fff;
        .ant-drawer-header-title {
            flex-direction: row-reverse;
            justify-content: flex-end;
            .ant-drawer-title {
                // display: none;
                font-family: $font-fam;
                text-align: center;
            }
        }
        .anticon-close {
            font-size: 13px;
        }
        .ant-drawer-close {
            background: #dbdbdb;
            padding: 6px;
            border: none;
            border-radius: 18px;
            color: #000;
            margin: 0;
            width: 22px;
            height: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
                transform: scale(0.9);
            }
        }
    }

    .select_input {
        outline: none;
    }

    .ant-drawer-body {
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
    }
    .ant-drawer-body::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }

    .about-closebtn {
        margin: auto;
        width: 52px;
        height: 5px;
        background: #dbdbdb;
        border-radius: 30px;
        margin-bottom: 24px;
    }
    .aboutus-images {
        width: 100%;
        height: 340px;
        margin: 0px 0 8px 0;
        img {
            width: 100%;
            height: 100%;
            border-radius: 24px;
            object-fit: cover;
        }
    }
}
// .address-drawer,
// .aboutus-drawer {
//     .ant-drawer-title {
//         display: none;
//     }
//     .ant-drawer-header-title {
//         flex-direction: row !important;
//     }
//     .ant-drawer-body {
//         padding: 56px 20px 20px 20px !important;
//     }
// }

.pdf-btn .grshare {
    visibility: hidden;
}
.pdf-btn:hover .grshare {
    visibility: visible;
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transition: all 0.2s ease-in-out 0s;
}
.tools-btn .hovered-content {
    visibility: hidden;
}
.tools-btn:hover .hovered-content {
    visibility: visible;
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transition: all 0.2s ease-in-out 0s;
}
.tools-btn {
    background-color: transparent;
    // border: 3px solid #ff0251;
    // color: #ff0251;
    transition: 0.3s;
}
.tools-btn:hover {
    animation: pulse 2s;
    transition: 0.3s;
}
@keyframes pulse {
    0% {
        transform: scale(1);
    }
    70% {
        transform: scale(0.97);
        opacity: 0.8;
        // background-color: #212121;
        // color: #fff;
    }
    100% {
        transform: scale(1);
    }
}
