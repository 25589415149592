.ant-drawer-content-wrapper {
    height: 100% !important;
    .general-drawer {
        max-height: calc(100vh - 63px);
        height: 90%;
        position: absolute;
        bottom: 0;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        .ant-drawer-body {
            padding: 70px 20px 20px 20px;
            @media only screen and (min-width: 768px) {
                padding: 80px 36px 36px 36px;
            }
        }

        .swiper {
            padding: 16px;
            border-radius: 11px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .aboutus-swiper {
            height: 286px;
            position: relative;
            img {
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
                border-radius: 11px !important;
                height: 100%;
            }

            .swiper-button-next,
            .swiper-button-prev {
                color: #dbdbdb;

                width: auto;
                &::after {
                    font-size: 16px;
                    font-weight: 800;
                }
            }
            .swiper-button-prev {
                left: 0;
            }
            .swiper-button-next {
                right: 0;
            }
        }
        .aboutus-content {
            h2 {
                font-weight: 700;
                font-size: 20px;
                line-height: 40px;
                text-align: center;
                letter-spacing: -0.42px;
                color: #042137;
                margin-bottom: 3px;
                font-family: $font-fam;
            }
            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                text-align: center;
                font-family: $font-fam;

                color: #375361;
            }
        }
        .address-container {
            display: flex;
            flex-direction: column;

            .google-map-code {
                width: 100%;
                margin-bottom: 38px;
            }
            h2 {
                margin-bottom: 20px;
                font-weight: 600;
                font-size: 20px;
                line-height: 23px;
                color: #000000;
                font-family: $font-fam;
            }
            .location {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-weight: 500;
                font-size: 19px;
                line-height: 24px;
                letter-spacing: -0.42px;
                color: #000000;
                margin-bottom: 30px;
                font-family: $font-fam;
            }
            .address-content {
                margin-bottom: 28px;
                &:last-child {
                    margin-bottom: 0;
                }
                h3 {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #ababab;
                    font-family: $font-fam;
                    margin-bottom: 16px;
                }
                .address-detail {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 28px;
                    letter-spacing: -0.42px;
                    color: #000000;
                    font-family: $font-fam;
                }
            }
        }
    }
}
