*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    box-sizing: inherit;
    scroll-behavior: smooth;
}

body {
    font-family: $font-fam;
    font-style: normal;
}
.container {
    padding: 74px 16px 0 16px;
}
.vm_wrapper {
    min-height: calc(100vh - 56px);
}
.title a {
    color: #0070f3;
    text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
    text-decoration: underline;
    cursor: pointer;
}

h1 {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: $primary-color;
}
h2 {
    margin: 0;
    font-weight: 500;
    font-size: 17.23px;
    line-height: 24px;
    color: $primary-color;
}
.subcontent {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: $primary-color;
}
.title,
.description {
    text-align: center;
}

.description {
    line-height: 1.5;
    font-size: 1.5rem;
}
input {
    // border: 1px solid #b3b3b3;
    border-radius: 4px;
    font-family: $font-fam;
    font-weight: 400;
    color: $gray-3;
    font-size: 15px;
    line-height: 140%;
    padding: 15px 8px;
    width: 100%;
    outline: none;
    &::placeholder {
        color: #8b8b8b;
        font-size: 13px;
        line-height: 140%;
        font-family: $font-fam;
    }
}
.form_field {
    margin: 26px 0 40px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.whole_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}
.errmsg {
    color: #f70000;
    margin-bottom: 0px;
    text-align: start;
    font-size: 12px;
    width: 100%;
}
.ant-notification-notice-message {
    margin: 0px !important;
    padding-inline-end: 46px;
}
.ant-notification-notice {
    background-color: #e6f0f8;
    border: 1px solid #c7cacc;
    font-weight: 500;
    font-size: 14.4846px;
    line-height: 139.3%;
    color: #181818;
    width: auto;
    display: flex;
    gap: 40px;
}
.link_content {
    text-decoration: none;
}
.left-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    .left-arrow__icon {
        color: #ffffff;
        font-size: 24px;
    }
}
@media only screen and (min-width: 425px) {
    .container {
        padding: 74px 20px 20px 20px;
    }
}
.lm_link {
    text-decoration: none;
}
button {
    cursor: pointer;
}
