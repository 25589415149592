.vm_wrapper {
    .front_container {
        width: 100%;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        .front_cover {
            width: 100%;
            min-height: 300px;
            height: 140px;
            position: relative;
            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: inline-block;
                background: -moz-linear-gradient(
                    0deg,
                    rgba(33, 33, 33, 0),
                    rgba(33, 33, 33, 0)
                );
                /* FF3.6+ */
                background: -webkit-gradient(
                    linear,
                    left top,
                    left bottom,
                    color-stop(0%, rgba(33, 33, 33, 0)),
                    color-stop(100%, rgba(33, 33, 33, 0))
                );
                /* Chrome,Safari4+ */
                background: -webkit-linear-gradient(
                    top,
                    rgba(33, 33, 33, 0) 0%,
                    rgba(33, 33, 33, 0) 100%
                );
                /* Chrome10+,Safari5.1+ */
                background: -o-linear-gradient(
                    top,
                    rgba(33, 33, 33, 0) 0%,
                    rgba(33, 33, 33, 0) 100%
                );
                /* Opera 11.10+ */
                background: -ms-linear-gradient(
                    top,
                    rgba(33, 33, 33, 0) 0%,
                    rgba(33, 33, 33, 0) 100%
                );
                /* IE10+ */
                background: linear-gradient(
                    to bottom,
                    rgba(33, 33, 33, 0) 0%,
                    rgba(33, 33, 33, 0) 100%
                );
                /* W3C */
                /* IE6-9 */
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .front_logo {
            display: flex;
            justify-content: center;
            align-items: center;
            top: 94px;
            width: 140px;
            height: 140px;
            border-radius: 50%;
            background-color: #ffffff;
            position: absolute;
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
        .front_bottom {
            background-color: $white;
            border-radius: 15px;
            width: 100%;
            position: absolute;
            top: 270px;
            padding: 20px;
            height: calc(100vh - 270px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .featured__cards {
                margin-top: 20px;
            }
            .swiper-button-prev {
                display: none;
            }
            .swiper-button-next {
                display: none;
            }
            .front_btn {
                @include dflexsb;
                padding: 16px 0;
                border-bottom: 0.524px solid $border-color;
                span {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    color: $primary-color;
                    cursor: pointer;
                }
            }
            .front_card {
                margin-top: 24px;
            }
            .page_footer {
                .footer {
                    padding: 0px;
                }
            }
        }
        .swiper-pagination {
            display: none;
        }
        .footer {
            margin-bottom: 24px;
        }
    }
    .cover_container {
        width: 50%;
        width: 50%;
        height: 100vh;
        position: fixed;
        right: 0px;
        top: 56px;
        display: none;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
@media only screen and (min-width: 860px) {
    .vm_wrapper {
        @include dflexsb;

        .front_container {
            width: 50%;
            height: 100vh;
            .front_bottom {
                width: 50%;
            }
        }
        .cover_container {
            display: block;
        }
    }
}
