.category-card {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
}

.whole_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    padding-bottom: 24px;
    @media only screen and (min-width: 768px) {
        padding: 0;
    }
    .restaurant_closed {
        width: 100%;
        position: relative;
    }
    .closed_notification {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        position: absolute;
        height: 32px;
        top: 90px;
        left: 55%;
        background-color: #ffffff;
        padding: 4px 16px;
        border-radius: 75px;
        margin-left: -90px;
        box-sizing: border-box;
        border-radius: 15px;
        background-color: #ffffff;
        color: #212121;
        font-weight: bold;
        text-align: center;
        box-shadow: 0 3px 0 rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .animateOpen {
        -webkit-animation: moveOpen 4s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-fill-mode: forwards;
    }
    /* Safari and Chrome */
    @keyframes moveOpen {
        from {
            -webkit-transform: translate(0, -100px);
        }
        10% {
            -webkit-transform: translate(0, 20px);
        }
        12% {
            -webkit-transform: translate(0, 22px);
        }
        16% {
            -webkit-transform: translate(0, 20px);
        }
        80% {
            -webkit-transform: translate(0, 20px);
        }
        85% {
            -webkit-transform: translate(0, 25px);
        }
        to {
            -webkit-transform: translate(0, -100px);
        }
    }
    .category {
        margin-top: 56px;
        .category-cover {
            width: 100%;
            height: 151px;
            box-shadow: 0 2px 11px rgb(0 0 0 / 10%);
            // @include breakpoint(md) {
            //     height: 250px;
            // }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: relative !important;
            }
        }
        .top-content {
            display: flex;
            align-items: center;
            gap: 4px;
            // justify-content: space-between;
            background: #ffffff;
            // box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.1);
            padding: 24px 16px 4px 16px;
            font-size: 18px;
            font-weight: 700;
            line-height: 140%;
            color: #1d1d1d;
        }
        .delivery_content {
            width: 100%;
            height: 151px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 50px;
            .delivery_category {
                top: 20%;
                left: 38%;
                background-color: #ffffff;
                padding: 4px 16px;
                border-radius: 75px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 800;
                font-size: 14px;
                line-height: 140%;
                text-align: center;
                color: $primary-color;
                .arrowdown {
                    margin-top: 3px;
                    margin-left: 8px;
                }
            }
        }
        .category_container {
            padding: 16px;
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 16px;
            @media only screen and (min-width: 768px) {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }
        }
        .food-items {
            position: relative;
            // transition: all 0.2s ease-in-out;
            @media only screen and (min-width: 768px) {
                width: calc(50% - 8px);
            }
            @media only screen and (min-width: 1360px) {
                width: calc(33.33% - 11px);
            }
            a {
                text-decoration: none;
            }
            .food-itemsimg {
                width: 100%;
                height: 36.5vw;
                position: relative;
                transition: all 0.1s ease-in-out;
                &:hover {
                    transform: scale(0.99);
                    @include breakpoint(md) {
                        transform: scale(1);
                    }
                }

                @media only screen and (min-width: 768px) {
                    height: 18.5vw;
                    max-height: 140px;
                }
                img {
                    border-radius: 3px;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    opacity: 1.6;
                    border-radius: 6px;
                }
            }
            .order_status {
                position: absolute;
                width: 100%;
                // height: 140px;
                top: calc(50% - 15px);
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-weight: 500;
                line-height: 140%;
                // padding-bottom: 30px;

                @include breakpoint(xxs) {
                    padding-bottom: 0px;
                }
                div {
                    color: #fff;
                    opacity: 1;
                    z-index: 1;
                }
            }

            .item_name {
                font-weight: medium;
                font-size: 18px;
                line-height: 140%;
                text-align: center;
                color: #fff;
                position: absolute;
                bottom: 6px;
                left: 16px;
                margin: 0px;
                z-index: 1;
                display: flex;
                gap: 4px;
            }
            .item_name span:first-child {
                text-transform: capitalize;
            }

            .item_name .availiable_time {
                font-size: 14px;
                // padding-top: 2px;
            }
            .item_nameinactive {
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
            }
        }

        .food-itemsimg:after {
            content: "";
            position: absolute;
            z-index: 1;
            border-radius: 6px;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: inline-block;
            // background-color: rgba(0, 0, 0, 0.3);
            background: linear-gradient(
                180deg,
                rgba(43, 43, 43, 0.3) 0%,
                rgba(0, 0, 0, 0.6) 88.59%
            );
            // background-color: rgba(0, 0, 0, 0.3);
            // background: linear-gradient(
            //     180deg,
            //     rgba(33, 33, 33, 0.68) 0,
            //     rgba(33, 33, 33, 0.4)
            // );
            // opacity: 0.3;
            // background-color: rgba(0, 0, 0, 0.5);
            // background: -webkit-gradient(
            //     linear,
            //     left top,
            //     left bottom,
            //     color-stop(0%, rgba(33, 33, 33, 0.6)),
            //     color-stop(100%, rgba(33, 33, 33, 0.85))
            // );

            // background: -webkit-linear-gradient(
            //     top,
            //     rgba(33, 33, 33, 0.85) 0%,
            //     rgba(33, 33, 33, 0.4) 100%
            // );

            // background: -o-linear-gradient(
            //     top,
            //     rgba(33, 33, 33, 0.68) 0%,
            //     rgba(33, 33, 33, 0.4) 100%
            // );

            // background: -ms-linear-gradient(
            //     top,
            //     rgba(33, 33, 33, 0.68) 0%,
            //     rgba(33, 33, 33, 0.4) 100%
            // );

            // background: linear-gradient(
            //     to bottom,
            //     rgba(33, 33, 33, 0.68) 0%,
            //     rgba(33, 33, 33, 0.4) 100%
            // );
        }
    }
}

.ant-popover {
    width: 300px !important;
    left: 10px !important;
    .ant-popover-title {
        padding: 10px;
        text-align: center;
    }
}

.ant-popover-inner-content {
    display: flex;
    justify-content: space-between;
}

.venue-operating-hour {
    padding: 16px;

    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: $primary-color;
    cursor: pointer;
    background-color: $primary-color;
    color: #ffffff;
    width: 140px;
    margin: 16px 16px 0px 16px;
    border-radius: 8px;
}

.bookinghours-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    .bookinghours {
        width: 48%;
    }
    // margin-bottom: 24px;
    .bookinghours_title {
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        color: $primary-color;
    }
    .time {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: $primary-color;
    }
}

.bookinghours-wrapper .bookinghours:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    border-left: 1px solid #e9e9e9;
}
